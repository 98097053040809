import * as i0 from '@angular/core';
import { InjectionToken, forwardRef, EventEmitter, Component, Inject, Optional, Input, Output, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
function UiSwitchComponent_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 2)(1, "span", 3);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 4);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("color", ctx_r0.getColor("checkedTextColor"));
    i0.ɵɵattribute("aria-label", ctx_r0.checkedLabel);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.checkedLabel);
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("color", ctx_r0.getColor("uncheckedTextColor"));
    i0.ɵɵattribute("aria-label", ctx_r0.uncheckedLabel);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.uncheckedLabel);
  }
}
const _c0 = ["*"];
const UI_SWITCH_OPTIONS = new InjectionToken('UI_SWITCH_OPTIONS');
const UI_SWITCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UiSwitchComponent),
  multi: true
};
class UiSwitchComponent {
  constructor(config = {}, cdr) {
    this.cdr = cdr;
    /**
     * Emits changed value
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.change = new EventEmitter();
    /**
     * Emits DOM event
     */
    this.changeEvent = new EventEmitter();
    /**
     * Emits changed value
     */
    this.valueChange = new EventEmitter();
    this.onTouchedCallback = v => {};
    this.onChangeCallback = v => {};
    this.size = config && config.size || 'medium';
    this.color = config && config.color;
    this.switchOffColor = config && config.switchOffColor;
    this.switchColor = config && config.switchColor;
    this.defaultBgColor = config && config.defaultBgColor;
    this.defaultBoColor = config && config.defaultBoColor;
    this.checkedLabel = config && config.checkedLabel;
    this.uncheckedLabel = config && config.uncheckedLabel;
    this.checkedTextColor = config && config.checkedTextColor;
    this.uncheckedTextColor = config && config.uncheckedTextColor;
  }
  set checked(v) {
    this._checked = v !== false;
  }
  get checked() {
    return this._checked;
  }
  set disabled(v) {
    this._disabled = v !== false;
  }
  get disabled() {
    return this._disabled;
  }
  set reverse(v) {
    this._reverse = v !== false;
  }
  get reverse() {
    return this._reverse;
  }
  set loading(v) {
    this._loading = v !== false;
  }
  get loading() {
    return this._loading;
  }
  getColor(flag = '') {
    if (flag === 'borderColor') {
      return this.defaultBoColor;
    }
    if (flag === 'switchColor') {
      if (this.reverse) {
        return !this.checked ? this.switchColor : this.switchOffColor || this.switchColor;
      }
      return this.checked ? this.switchColor : this.switchOffColor || this.switchColor;
    }
    if (flag === 'checkedTextColor') {
      return this.reverse ? this.uncheckedTextColor : this.checkedTextColor;
    }
    if (flag === 'uncheckedTextColor') {
      return this.reverse ? this.checkedTextColor : this.uncheckedTextColor;
    }
    if (this.reverse) {
      return !this.checked ? this.color : this.defaultBgColor;
    }
    return this.checked ? this.color : this.defaultBgColor;
  }
  onClick(event) {
    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    // Component events
    this.change.emit(this.checked);
    this.valueChange.emit(this.checked);
    this.changeEvent.emit(event);
    // value accessor callbacks
    this.onChangeCallback(this.checked);
    this.onTouchedCallback(this.checked);
    this.cdr.markForCheck();
  }
  onToggle(event) {
    if (this.disabled) {
      return;
    }
    if (this.beforeChange) {
      this._beforeChange = this.beforeChange.subscribe(confirm => {
        if (confirm) {
          this.onClick(event);
        }
      });
    } else {
      this.onClick(event);
    }
  }
  writeValue(obj) {
    if (obj !== this.checked) {
      this.checked = !!obj;
    }
    this.onChangeCallback(this.checked);
    if (this.cdr) {
      this.cdr.markForCheck();
    }
  }
  registerOnChange(fn) {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  ngOnDestroy() {
    if (this._beforeChange) {
      this._beforeChange.unsubscribe();
    }
  }
}
UiSwitchComponent.ɵfac = function UiSwitchComponent_Factory(t) {
  return new (t || UiSwitchComponent)(i0.ɵɵdirectiveInject(UI_SWITCH_OPTIONS, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
UiSwitchComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: UiSwitchComponent,
  selectors: [["ui-switch"]],
  hostBindings: function UiSwitchComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function UiSwitchComponent_click_HostBindingHandler($event) {
        return ctx.onToggle($event);
      });
    }
  },
  inputs: {
    size: "size",
    color: "color",
    switchOffColor: "switchOffColor",
    switchColor: "switchColor",
    defaultBgColor: "defaultBgColor",
    defaultBoColor: "defaultBoColor",
    checkedLabel: "checkedLabel",
    uncheckedLabel: "uncheckedLabel",
    checkedTextColor: "checkedTextColor",
    uncheckedTextColor: "uncheckedTextColor",
    beforeChange: "beforeChange",
    ariaLabel: "ariaLabel",
    checked: "checked",
    disabled: "disabled",
    reverse: "reverse",
    loading: "loading"
  },
  outputs: {
    change: "change",
    changeEvent: "changeEvent",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([UI_SWITCH_CONTROL_VALUE_ACCESSOR])],
  ngContentSelectors: _c0,
  decls: 4,
  vars: 21,
  consts: [["type", "button", "role", "switch", 1, "switch"], ["class", "switch-pane", 4, "ngIf"], [1, "switch-pane"], [1, "switch-label-checked"], [1, "switch-label-unchecked"]],
  template: function UiSwitchComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵtemplate(1, UiSwitchComponent_label_1_Template, 5, 8, "label", 1);
      i0.ɵɵelementStart(2, "small");
      i0.ɵɵprojection(3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("background-color", ctx.getColor())("border-color", ctx.getColor("borderColor"));
      i0.ɵɵclassProp("checked", ctx.checked)("disabled", ctx.disabled)("loading", ctx.loading)("switch-large", ctx.size === "large")("switch-medium", ctx.size === "medium")("switch-small", ctx.size === "small");
      i0.ɵɵattribute("aria-checked", ctx.checked)("aria-label", ctx.ariaLabel);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.checkedLabel || ctx.uncheckedLabel);
      i0.ɵɵadvance();
      i0.ɵɵstyleProp("background", ctx.getColor("switchColor"));
    }
  },
  dependencies: [i1.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UiSwitchComponent, [{
    type: Component,
    args: [{
      selector: 'ui-switch',
      providers: [UI_SWITCH_CONTROL_VALUE_ACCESSOR],
      template: "<button\n  type=\"button\"\n  class=\"switch\"\n  role=\"switch\"\n  [attr.aria-checked]=\"checked\"\n  [attr.aria-label]=\"ariaLabel\"\n  [class.checked]=\"checked\"\n  [class.disabled]=\"disabled\"\n  [class.loading]=\"loading\"\n  [class.switch-large]=\"size === 'large'\"\n  [class.switch-medium]=\"size === 'medium'\"\n  [class.switch-small]=\"size === 'small'\"\n  [style.background-color]=\"getColor()\"\n  [style.border-color]=\"getColor('borderColor')\"\n>\n  <label class=\"switch-pane\" *ngIf=\"checkedLabel || uncheckedLabel\">\n    <span\n      [attr.aria-label]=\"this.checkedLabel\"\n      class=\"switch-label-checked\"\n      [style.color]=\"getColor('checkedTextColor')\"\n      >{{ this.checkedLabel }}</span\n    >\n    <span\n      [attr.aria-label]=\"this.uncheckedLabel\"\n      class=\"switch-label-unchecked\"\n      [style.color]=\"getColor('uncheckedTextColor')\"\n      >{{ this.uncheckedLabel }}</span\n    >\n  </label>\n  <small [style.background]=\"getColor('switchColor')\">\n    <ng-content></ng-content>\n  </small>\n</button>\n"
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [UI_SWITCH_OPTIONS]
      }, {
        type: Optional
      }]
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    size: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    switchOffColor: [{
      type: Input
    }],
    switchColor: [{
      type: Input
    }],
    defaultBgColor: [{
      type: Input
    }],
    defaultBoColor: [{
      type: Input
    }],
    checkedLabel: [{
      type: Input
    }],
    uncheckedLabel: [{
      type: Input
    }],
    checkedTextColor: [{
      type: Input
    }],
    uncheckedTextColor: [{
      type: Input
    }],
    beforeChange: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    checked: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    reverse: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    change: [{
      type: Output
    }],
    changeEvent: [{
      type: Output
    }],
    valueChange: [{
      type: Output
    }],
    onToggle: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class UiSwitchModule {
  static forRoot(config) {
    return {
      ngModule: UiSwitchModule,
      providers: [{
        provide: UI_SWITCH_OPTIONS,
        useValue: config || {}
      }]
    };
  }
}
UiSwitchModule.ɵfac = function UiSwitchModule_Factory(t) {
  return new (t || UiSwitchModule)();
};
UiSwitchModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: UiSwitchModule
});
UiSwitchModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, FormsModule, FormsModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UiSwitchModule, [{
    type: NgModule,
    args: [{
      declarations: [UiSwitchComponent],
      imports: [CommonModule, FormsModule],
      exports: [FormsModule, UiSwitchComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { UiSwitchComponent, UiSwitchModule };
